import React from "react";
import SubscriptionForm from "./components/SubscriptionForm";
import { motion } from "framer-motion";
import { BrowserRouter, Route, Routes } from "react-router-dom";

class Subscription extends React.Component {
  render() {
    return (
      <motion.div
        initial={{ backgroundColor: "#ffffff" }} // Initial background color
        animate={{
          backgroundColor: ["#ffffff"],
        }} // Final background color
        transition={{
          duration: 15,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
        style={{ height: "100vh", width: "100vw", overflowX: "auto" }} // Ensure div covers the whole page
      >
        <div className="flex min-h-screen flex-col justify-center px-8 sm:items-start md:items-center lg:items-center">
          <p className="inter-bold mb-6 text-3xl font-bold text-gray-800 sm:text-4xl">
            APINATA{" "}
            <span className="sm:hidden">
              <br />
            </span>{" "}
            Equity Management
          </p>
          {/*<p className="text-gray-800 font-bold text-xl sm:text-2xl mb-10">Coming soon...</p>*/}
          <SubscriptionForm />
          {/*<SignUpFormUgly/>*/}
        </div>
      </motion.div>
    );
  }
}

const SlideComponent = ({ filename }: { filename: string }) => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <iframe
        src={`/slides/${filename}.pdf`}
        title="Presentation Slide"
        style={{ width: "100%", height: "100%", border: 0 }}
      >
        This browser does not support PDFs. Please download the PDF to view it:{" "}
        <a href={`/slides/${filename}.pdf`}>Download PDF</a>.
      </iframe>
    </div>
  );
};

function App() {
  var filenames = [
    "Bootstrapping-tools-and-protocols",
    "Eliminate-avoidable-risks",
    "Founders",
    "Instant-legal-clarity-and-compliance",
    "Investors",
    "Landing-page",
    "Stay-efficient-with-low-touch-experience",
    "Strategic-speed-and-agility",
    "Support-your-startup-in-new-ways",
  ];

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Subscription />} />
          {filenames.map((filename) => (
            <Route
              key={filename}
              path={`/${filename.toLowerCase()}`}
              element={<SlideComponent filename={filename} />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
