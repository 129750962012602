import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

const SubscriptionForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Reset states
    setError(false);
    setSuccess(false);

    const formData = new FormData();
    formData.append("EMAIL", email);
    formData.append("email_address_check", "");
    formData.append("locale", "en");

    // Here you would integrate your form submission logic, possibly using fetch or axios
    // For demonstration, let's just simulate a success response after a delay
    try {
      const res = await fetch(
        "https://fd6b513e.sibforms.com/serve/MUIFAIgjEs3AK6nqoQs-IDy7bLFaTgrI7Pv7jGnOflW_dr9FmkIFre1RS0QHIdJzT7kE4bY84jGq87TCPh868zCLGeyf2j56-UNevWsR237qKJQlYmDbOsnuYWppSioprnehFE6v-rmKFc8sAsg_c1SdCK1D-FhEahyVIOdpzW4EvXFF1c_SaSXgZOHJQqV8M4-sF_kj-ayeOB1E?isAjax=1",
        {
          method: "POST",
          body: formData,
        },
      );
      if (res.ok) {
        setSuccess(true);
        setEmail("");
        return;
      } else {
        setError(true);
        setEmail("");
        return;
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div className="sib-form">
      <div id="sib-form-container" className="sib-form-container">
        {error && (
          <div className="sib-form-message-panel mb-2">
            Please enter a valid email address
          </div>
        )}
        {success && (
          <div className="sib-form-message-panel mb-2">
            Your subscription has been successful
          </div>
        )}
        <div
          id="sib-container"
          className="sib-container--large sib-container--vertical"
        >
          <form
            id="sib-form"
            className="flex flex-col gap-4 sm:flex-row md:items-center lg:items-center"
            onSubmit={handleSubmit}
          >
            <div className="sib-input sib-form-block">
              <div className="form__entry entry_block">
                <input
                  autoFocus
                  className={twMerge(
                    "w-full min-w-[20rem] rounded-xl border-2 border-gray-600 px-4 py-2 text-gray-600 sm:w-[30rem] md:w-[30rem]",
                    "focus: outline-none ring-0",
                  )}
                  type="text"
                  id="EMAIL"
                  name="EMAIL"
                  autoComplete="off"
                  placeholder="Enter your email for updates about the upcoming launch"
                  data-required="true"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="sib-form-block__button rounded-xl border border-transparent bg-gray-900 px-6 py-2 text-xl font-bold text-white hover:bg-gray-700"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionForm;

// Remember to include your styles in a separate CSS/SCSS file if not embedded directly
